/* ---------------------------------------------------
    SHADOW VARIABLES
----------------------------------------------------- */
$shadow-custom-dropdown: 0px 3px 6px rgba(15, 15, 15, 0.1), 0px 9px 24px rgba(15, 15, 15, 0.2);
$shadow-modal          : 0px 5px 10px rgb(15,15,15 / 10%),  0px 15px 40px  rgb(15,15, 15 / 20%) ;
$shadow-button-primary : 0px 1px 2px rgba(15, 15, 15, 0.1), inset 0px 0px 1px rgba(15, 15, 15, 0.1);
$shadow-top-header     : 0px 1px 4px 0px rgba(9, 30, 66, 0.13);
$shadow-card           : 1px 2px 8px 1px rgba(0, 0, 0, 0.06);
$shadow-highlight      : 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
$shadow-auth           : 0px 12px 48px rgba(26, 33, 52, 0.11);

.shadow-custom-dropdown{box-shadow: $shadow-custom-dropdown }
.shadow-modal          {box-shadow: $shadow-modal }
.shadow-button-primary {box-shadow: $shadow-button-primary }
.shadow-top-header     {box-shadow: $shadow-top-header }
.shadow-card           {box-shadow: $shadow-card }
.shadow-highlight      {box-shadow: $shadow-highlight }
.shadow-none           {box-shadow: none !important }