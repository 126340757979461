
#CoverImageDrop{
    .inputImageBox{
        border: 1px solid color(neutral20);
        border-style: dashed;
        box-sizing: border-box;
        overflow: hidden;
        height: 147px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title{
        color: color(neutral100);
    }
    .subHeading{
        color: color(neutral60);
    }

    .dropImageCircle{
        width: calc(200px * 2.66px);
        // height: 276.87px;
        // // height: 204px;
        // // height: 92px;
        // margin: auto;
        // border: 1px dashed color(neutral20);
        // box-sizing: border-box;
        // justify-content: center;
        // display: flex;
        // flex-direction: column;
        // transition: background-color ease .7s;
        // width: 100%;
        height: 152px;
        margin: auto;
        border: 1px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 4px;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        flex-direction: column;
        &:hover{
            &.image{
                background-color: rgba(0,0,0,.6);
            }
            .imageUpload{
                opacity         : 1;
                z-index         : 100;
                // background-color: color(neutral20);
            }
            .title{
                color: color(white) !important;
            }

            .subHeading{
                color:  color(white) !important;
            }
        }
    }


    .uploadHover:hover{
        background-color: rgba(0,0,0,.6) !important;
        color: color(white);
        .title{
            color: color(white) !important;
        }
        .subHeading{
            color: color(white) !important;
        }

    }

    .dropImageCircle:hover{
        // background-color: rgba(0,0,0,.6);
        // color: color(white) !important;
        .title{
            color: color(neutral100) !important;
        }
        .subHeading{
            color: color(neutral60) !important;
        }


    }

    .imageUpload{
        transition: opacity ease 0.7s;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .imageUpload.remove{
        opacity: 0;
    }

    .inputImageBox:hover{
        .title{
            color: color(white) !important;
        }
        .subHeading{
            color: color(white) !important;
        }
    }

    .insideImage{
        width: inherit;
        height: inherit;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    @media (min-width: 1900px) {
        .dropImageCircle {
            height: 204px;
            width: 533px;
        }
    }
}